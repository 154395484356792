import Vue from 'vue'
import Vuex from 'vuex'

import vares from './vares'
import realizedvolecharts from './realizedvolecharts'

import price from './price'
import fengge from './fengge'
import zongjie from './zongjie'
import sign from './sign'
Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        vares,
        realizedvolecharts,
        price,
        fengge,
        zongjie,
        sign
    }
})