import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
    // mode: 'history',
    routes: [
        {
            path: '/',
            redirect: '/product',
            // component: resolve => require(['../view/Market'], resolve),
        },
        {
            path: '/invest',
            name: 'Invest',
            component: resolve => require(['../view/Invest'], resolve)
        },
        {
            path: '/market',
            name: 'Market',
            component: resolve => require(['../view/Market'], resolve),
            redirect: '/market/hushen',
            children:[
                {
                    path:'hushen',
                    name:'HuShen',
                    component:resolve => require(['../view/Market/HuShen'], resolve),
                },
                {
                    path:'america',
                    name:'America',
                    component:resolve => require(['../view/Market/America'], resolve),
                },
                {
                    path:'asiapacific',
                    name:'AsiaPacific',
                    component:resolve => require(['../view/Market/AsiaPacific'], resolve),
                },
                {
                    path:'europe',
                    name:'Europe',
                    component:resolve => require(['../view/Market/Europe'], resolve),
                },
                {
                    path:'fengge',
                    name:'FengGe',
                    component:resolve => require(['../view/Market/FengGe'], resolve),
                },

            ]
        },
        {
            path: '/customer',
            name: 'Customer',
            component: resolve => require(['../view/Customer'], resolve),
            // children:[
            //     {
            //         path:'/assetallocation',
            //         name:'AssetAllocation',
            //         component:resolve => require(['../view/Customer/AssetAllocation'], resolve),
            //     },
            //     {
            //        path:'/performancemanage',
            //        name:'PerformanceManage',
            //        component:resolve => require(['../view/Customer/PerformanceManage'], resolve),
            //     }
                

            // ]
        },
        {
            path:'/assetallocation',
            name:'AssetAllocation',
            component:resolve => require(['../view/Customer/AssetAllocation'], resolve),
        },
        {
           path:'/performancemanage',
           name:'PerformanceManage',
           component:resolve => require(['../view/Customer/PerformanceManage'], resolve),
        },
        {
            path:'/riskmanage',
            name:'RiskManage',
            component:resolve => require(['../view/Customer/RiskManage'], resolve),
         },
        {
            path: '/product',
            name: 'ProductAndService',
            component: resolve => require(['../view/ProductAndService'], resolve),
        },
        {
            path:'/attributionperform',
            name:'AttributionPerform',
            component:resolve => require(['../view/ProductAndService/AttributionPerform'], resolve),
        },
        {
            path:'/riskmeasurement',
            name:'RiskMeasurement',
            component:resolve => require(['../view/ProductAndService/RiskMeasurement'], resolve),
        },
        {
            path:'/combinatorialoptimization',
            name:'CombinatorialOptimization',
            component:resolve => require(['../view/ProductAndService/CombinatorialOptimization'], resolve),
        },
        {
            path: '/about',
            name: 'AboutUs',
            component: resolve => require(['../view/AboutUs'], resolve),
        }
        
    ]
})
// beforeEach是router的钩子函数，在进入路由前执行
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})
export default router
