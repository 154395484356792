const state = {
    visible: false
}

const mutations = {
    setVisible(state, data) {
        state.visible = data
    }
}

export default {
    namespaced: true,
    state,
    mutations
}