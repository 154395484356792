<template>
    <div :class="['container nav-bar', (activeMenu !== '/' || this.isScroll) ? 'with-bg' : '']">
        <div class="content">
            <img class="logo" src="../assets/logo.png" alt="">
            <el-menu :default-active="activeIndex"
                mode="horizontal"
                background-color="#2E5082"
                text-color="#fff"
                active-text-color="#fff"
                :router="true"
            >
              <!-- <el-menu-item index="/invest" class="tab1" >投顾平台</el-menu-item> -->
              <el-menu-item index="/product" class="tab1">产品中心</el-menu-item>
              <el-menu-item index="/market" class="tab1">风险预测</el-menu-item>

              <!-- <el-submenu index="/customer" class="tab2">
                <template slot="title">解决方案</template>
                <el-menu-item index="/customer">基金管理</el-menu-item>
                <el-menu-item index="/assetallocation">资产配置</el-menu-item>
                <el-menu-item index="/performancemanage">绩效管理</el-menu-item>
                <el-menu-item index="/riskmanage">风险管理</el-menu-item>
              </el-submenu> -->
              <!-- <el-submenu index="/product" class="tab2">
                  <template slot="title">产品分类</template>
                    <el-menu-item index="/product">公募基金投资</el-menu-item>
                    <el-menu-item index="/attributionperform">绩效归因</el-menu-item>
                    <el-menu-item index="/riskmeasurement">风险计量</el-menu-item>
                    <el-menu-item index="/combinatorialoptimization">投资组合优化</el-menu-item>
              </el-submenu> -->

              <el-submenu index="/about" class="tab2">
                  <template slot="title">关于我们</template>
                  <el-menu-item index="/about" @click="introduce">公司介绍</el-menu-item>
                  <el-menu-item index="/about" @click="contact">联系我们</el-menu-item>
              </el-submenu>
            </el-menu>
            <div class="sign">
              <el-button round @click="signOn">注册</el-button>
              <el-button round @click="toLogin" type="primary" >登录</el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NavBar',
    data() {
        return {
            activeMenu: '',
            isScroll: false,
            isActive:{
                type: Boolean,
                default: false
            },
            activeIndex: '',
        }
    },
    watch: {
        $route(to) {
            this.activeMenu = to.path
            this.activeIndex = '/' + this.activeMenu.split('/')[1]
        }
    },
    mounted() {
        this.activeMenu = this.$route.path
        this.activeIndex = '/' + this.activeMenu.split('/')[1]
        window.addEventListener('scroll', this.handleScroll)
    },
    methods: {
        handleScroll() {
            let scrollY = window.scrollY
            if (scrollY > 0) {
                this.isScroll = true
            } else {
                this.isScroll = false
            }
        },
        
        introduce() {
            location.href="#introduce"
        },
        advantage() {
            location.href="#advantage"
        },
        contact() {
            location.href="#contact"
        },
        signOn() {
            this.$store.commit('sign/setVisible', true)
        },
        toLogin() {
            location.href = 'https://amp.zhijuninvest.com'
        }
    }
}
</script>

<style lang="less" scoped>
.nav-bar{
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 100%;
    color: #FFFFFF;
    &.with-bg{
        width:100%;
        height:80px;
        background: #2E5082;
    }
    .content{
        height: 80px;
        line-height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .logo{
        // width: 170px;
        margin-bottom: 4px;
    }
}
</style>


<style lang="less" scoped>
.nav-bar /deep/ .el-menu.el-menu--horizontal{
    border-bottom: 0;
    .el-menu-item, .el-submenu .el-submenu__title{
        width: 144px;
        height: 80px;
        line-height: 80px;
        padding: 0;
        transition: none;
        font-size: 16px;
        .el-submenu__icon-arrow{
            color: #fff;
        }
    }
    .el-menu-item.is-active, .el-submenu.is-active .el-submenu__title{
        border-bottom: 0;
        font-weight: bold;
        &::after{
            content: '';
            position: absolute;
            height: 3px;
            background: #fff;
            bottom: 18px;
            left: 50%;
        }
    }
    .tab1.is-active::after{
        width: 64px;
        margin-left: -32px;
    }
    .tab2.is-active .el-submenu__title::after{
        width: 80px;
        margin-left: -40px;
    }
}
</style>

<style lang="less">
.el-menu--horizontal a{
    text-decoration: none;
    color: #ffffff;
    font-size: 16px !important;
    font-weight: normal;
    display: block;
    width: 100%;
}
.sign{
    .el-button{
        padding: 8px 34px;
    }
    .el-button:first-child{
        color: #2E5082;
    }
    .el-button--primary.el-button.is-round{
        background:transparent;
        border-color:#fff;
    }
}
div.el-menu--horizontal .el-menu--popup{
    min-width: 144px;
    text-align: center;
    box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.5);
    opacity: 0.95;
    margin: 0;
    padding: 10px 0 24px 0;
    border-radius: 0;
    .el-menu-item{
        height: 42px;
        line-height: 42px;
        
        &:hover{
            background: transparent !important;
            a{
                font-size: 20px !important;
                font-weight: 500 !important;
            }
        }
    }
    
    &::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 5px;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0,0,0,1), rgba(0,0,0,0));
        opacity: 0.21;
    }
}
</style>