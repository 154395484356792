// import echarts from 'echarts/lib/echarts'
import { post } from '../http'

const state = {
    pricedata:{}
}

const mutations = {
    SET_PRICEDATA: (state, pricedata) => {
        state.pricedata = pricedata
    }
}

const actions = {
    getLatestIndexPriceAndReturn({ commit },params) {
        return new Promise((resolve, reject) => {
            let _params={"index_code": params};
            
            post('/api/model/getLatestIndexPriceAndReturn', _params).then(res => {
                let pricedata = res.data;
                // console.log(pricedata)
                commit('SET_PRICEDATA', pricedata) ; 
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
