// import echarts from 'echarts/lib/echarts'
import { post } from '../http'


const state = {
    shouyiData:{},
    bodongData:{},
    sytableData:[],
    bdtableData:[],
}

const mutations = {
    SET_SHOUYIDATA: (state, shouyiData) => {
        state.shouyiData = shouyiData
    },
    SET_BODONGDATA: (state, bodongData) => {
        state.bodongData = bodongData
    },
    SET_SYTABLEDATA: (state, sytableData) => {
        state.sytableData = sytableData
    },
    SET_BDTABLEDATA: (state, bdtableData) => {
        state.bdtableData = bdtableData
    }
}
const factor = [
    {label: '贝塔', value: 'beta'},
    {label: '动量', value: 'momentum'},
    {label: '规模', value: 'size'},
    {label: '盈利', value: 'earnings_yield'},
    {label: '波动', value: 'residual_vol'},
    {label: '成长', value: 'growth'},
    {label: '价值', value: 'book_to_price'},
    {label: '杠杆', value: 'leverage'},
    {label: '流动性', value: 'liquidity'},
    {label: '中盘', value: 'non_linear_size'}
];  
function toPercent(point,withColor,isHushenFont){
    let str = Number(point*100).toFixed(2) + '%'
    if (!withColor) {
        return `<span style="font-weight: 600;" class="arrow-trans">${+str > 0 ? '+' : ''}${str}</span>`
    }
    // 沪深指数 正是红色，负数是绿色，其余指数相反
    if (point>0) {
        if (isHushenFont) {
            return  `<span style="color:#E64545;font-weight:600;" class="arrow-up">+${str}</span>`
        } else {
            return `<span style="color:#67B35A;font-weight:600;" class="arrow-up">+${str}</span>`
        }
    } else {
        if (isHushenFont) {
            return `<span style="color:#67B35A;font-weight:600;" class="arrow-down">${str}</span>`
        } else {
            return `<span style="color:#E64545;font-weight:600;" class="arrow-down">${str}</span>`
        }                        
    }    
}

function sytableDataAll(shouyiData, isHushenFont){
    if (typeof(shouyiData.syTableData) == "undefined"){ 
        return
    }
    let sytableData = [
        {
            name:`<span class="bold">累计收益率</span><br/>
                <span>${shouyiData.startDate.replace(/-/g,'/')+"至"+shouyiData.endDate.replace(/-/g,'/')}</span>`,
            data: ''
        },
        {
            name:'<span>贝塔</span>',
            data:toPercent(shouyiData.syTableData.beta)
        } ,
        {
            name:'<span>动量</span>',
            data:toPercent(shouyiData.syTableData.momentum)
        } ,
        {
            name:'<span>规模</span>',
            data:toPercent(shouyiData.syTableData.size)
        } ,
        {
            name:'<span>盈利</span>',
            data:toPercent(shouyiData.syTableData.earnings_yield)
        } , 
        {
            name:'<span>波动</span>',
            data:toPercent(shouyiData.syTableData.residual_vol)
        } ,
        {
            name:'<span>成长</span>',
            data:toPercent(shouyiData.syTableData.growth)
        } ,
        {
            name:'<span>价值</span>',
            data:toPercent(shouyiData.syTableData.book_to_price)
        } ,   
        {
            name:'<span>杠杆</span>',
            data:toPercent(shouyiData.syTableData.leverage)
        } ,
        {
            name:'<span>流动性</span>',
            data:toPercent(shouyiData.syTableData.liquidity)
        } ,
        {
            name:'<span>中盘</span>',
            data:toPercent(shouyiData.syTableData.non_linear_size)
        } ,
    ] ;  
    return sytableData;
}

function bdtableDataAll(bodongData, isHushenFont){
    if (typeof(bodongData.all_factor_realized_vols_table) == "undefined"){ 
        return
    }
    let td = bodongData.all_factor_realized_vols_table.TradingDay.replace(/-/g,'/').substr(0,10).replace(/-/g,'/');
    let bdtableData=[
        {
            name:`<span class="bold">波动率比较</span><br/>
                <span>截止${td}</span>`,
            data: ''
        } ,
        {
            name:'<span>贝塔</span>',
            data:toPercent(bodongData.all_factor_realized_vols_table.beta[0])
        } ,
        {
            name:'<span>动量</span>',
            data:toPercent(bodongData.all_factor_realized_vols_table.momentum[0])
        } ,
        {
            name:'<span>规模</span>',
            data:toPercent(bodongData.all_factor_realized_vols_table.size[0])
        } ,
        {
            name:'<span>盈利</span>',
            data:toPercent(bodongData.all_factor_realized_vols_table.earnings_yield[0])
        } ,
        {
            name:'<span>波动</span>',
            data:toPercent(bodongData.all_factor_realized_vols_table.residual_vol[0])
        } ,
        {
            name:'<span>成长</span>',
            data:toPercent(bodongData.all_factor_realized_vols_table.growth[0])
        } ,
        {
            name:'<span>价值</span>',
            data:toPercent(bodongData.all_factor_realized_vols_table.book_to_price[0])
        } ,
        {
            name:'<span>杠杆</span>',
            data:toPercent(bodongData.all_factor_realized_vols_table.leverage[0])
        } ,
        {
            name:'<span>流动性</span>',
            data:toPercent(bodongData.all_factor_realized_vols_table.liquidity[0])
        } ,
        {
            name:'<span>中盘</span>',
            data:toPercent(bodongData.all_factor_realized_vols_table.non_linear_size[0])
        } ,
    ]
    return bdtableData;
}

const actions = {
    getFactorCumulativeReturnForeground({ commit },params) {
        return new Promise((resolve, reject) => {
            let _params={};
            let _isHushenFont = params.isHushenFont;
            post('/api/model/getFactorCumulativeReturnForeground', _params).then(res => {
                let shouyiData = res.data;
                let startDate = shouyiData.TradingDay[1].replace(/-/g,'/').substr(0,11).replace(/-/g,'/');
                let endDate = shouyiData.TradingDay[shouyiData.TradingDay.length-1].replace(/-/g,'/').substr(0,11).replace(/-/g,'/');
                shouyiData.startDate = startDate;
                shouyiData.endDate = endDate;
                commit('SET_SHOUYIDATA', shouyiData) ; 
                //生成收益表格数据
                commit('SET_SYTABLEDATA', sytableDataAll(shouyiData,_isHushenFont)) ; 
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    getFactorRealizedVolsChartForeground({ commit },params) {
        return new Promise((resolve, reject) => {
            let _params={};
            let _isHushenFont = params.isHushenFont;      
            post('/api/model/getFactorRealizedVolsChartForeground', _params).then(res => {
                let bodongData = res.data;
                let td = bodongData.all_factor_realized_vols_table.TradingDay[0].substr(0,11);
                bodongData.all_factor_realized_vols_table.TradingDay = td
                commit('SET_BODONGDATA', bodongData) ; 
                //生成波动表格数据
                let bdtabledData = bdtableDataAll(bodongData,_isHushenFont)
                commit('SET_BDTABLEDATA', bdtabledData) ; 
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },  
    sySingleTable({ commit },params) {
        let _shouyiData = this.state.fengge.shouyiData;     
        //console.log(factor)  
        return new Promise(() => {
            let isHushenFont = params.isHushenFont;
            let yinzi = factor[params.index].value;
            let sytableData = [
                {
                    name:`<span class="bold">累计收益率</span><br/>
                        <span>${_shouyiData.startDate.replace(/-/g,'/')}
                        至${_shouyiData.endDate.replace(/-/g,'/')}</span>`,
                    data: ''
                },
                {
                    name:'<span>累计收益率</span>',
                    data:toPercent(_shouyiData.cumulative_returns_by_periods[yinzi][4],true,isHushenFont)
                } ,
                {
                    name:'<span>近一日收益率</span>',
                    data:toPercent(_shouyiData.cumulative_returns_by_periods[yinzi][0],true,isHushenFont)
                } ,
                {
                    name:'<span>近5日收益率</span>',
                    data:toPercent(_shouyiData.cumulative_returns_by_periods[yinzi][1],true,isHushenFont)
                } ,
                {
                    name:'<span>近20日收益率</span>',
                    data:toPercent(_shouyiData.cumulative_returns_by_periods[yinzi][2],true,isHushenFont)
                } , 
                {
                    name:'<span>近60日收益率</span>',
                    data:toPercent(_shouyiData.cumulative_returns_by_periods[yinzi][3],true,isHushenFont)
                }                                  
            ] ;  
            commit('SET_SYTABLEDATA', sytableData) ;        
        })
    },
    syTableAll({ commit },params) {
        //console.log(factor)
        return new Promise(() => {
            let isHushenFont = params.isHushenFont;
            commit('SET_SYTABLEDATA', sytableDataAll(this.state.fengge.shouyiData,isHushenFont)) ;      
        })
    },
    bdSingleTable({ commit },params) {
        let bddata = this.state.fengge.bodongData;     
        //console.log(factor)  
        return new Promise(() => {
            let isHushenFont = params.isHushenFont;
            let yinzi = factor[params.index].value;
            let td = bddata.individual_factor_realized_vols_table[yinzi].TradingDay[0].replace(/-/g,'/').substr(0,10).replace(/-/g,'/');
            let bdtableData=[
                {
                    name:`<span class="bold">波动率比较</span><br/>
                        <span>截止${td.replace(/-/g,'/')}</span>`,
                    data: ''
                } ,
                {
                    name:'<span>波动率&nbsp;(动态)</span>',
                    data:toPercent(bddata.individual_factor_realized_vols_table[yinzi].dynamic[0])
                } ,
                {
                    name:'<span>变化</span>',
                    data:toPercent(bddata.individual_factor_realized_vols_table[yinzi].dynamic_change[0],true,isHushenFont)
                } ,
                {
                    name:'<span>波动率&nbsp;(滚动)</span>',
                    data:toPercent(bddata.individual_factor_realized_vols_table[yinzi].rolling[0])
                } ,
                {
                    name:'<span>变化</span>',
                    data:toPercent(bddata.individual_factor_realized_vols_table[yinzi].rolling_change[0],true,isHushenFont)
                } ,
            ] 
            commit('SET_BDTABLEDATA', bdtableData) ;        
        })
    },
    bdTableAll({ commit },params) {
        //console.log(factor)  
        return new Promise(() => {
            let isHushenFont = params.isHushenFont;
            commit('SET_BDTABLEDATA', bdtableDataAll(this.state.fengge.bodongData,isHushenFont)) ;      
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
