// import echarts from 'echarts/lib/echarts'
import { post } from '../http'

const state = {
    option:{}
}

const mutations = {
    SET_OPTION: (state, option) => {
        state.option = option
    }
}

const actions = {
    getIndexRealizedVolsChart({ commit },params) {
        return new Promise((resolve, reject) => {
            let up_color = params.up_color;
            let down_color = params.down_color;
            let _params = {
                "confidence_interval": "99%",
                "forecast_period": 1,
                "index_code": params.index_code,
                "length": params.length,
                "method": params.method
            };   
            post('/api/model/getIndexRealizedVolsChart', _params).then(res => {
                let data = res.data;
                if (!data) {
                    commit('SET_OPTION', {}) ;
                    return
                }
                // 日期横坐标
                let length = data.realized_vol_chart.TradingDay.length;
                let td = data.realized_vol_chart.TradingDay.map(item => {
                    return item.substr(0,11);
                });
                td = td.slice(1,length);
                td = [...td, '', ''];
                // k线数据
                let values = [];
                for(let i=0;i<length;i++){
                    if(i==0){
                        continue;
                    }
                    let temp = [];
                    temp.push(data.realized_vol_chart.open[i]);
                    temp.push(data.realized_vol_chart.close[i]);
                    temp.push(data.realized_vol_chart.high[i]);
                    temp.push(data.realized_vol_chart.low[i]);
                    temp.push(data.realized_vol_chart.growth_rate[i]);
                    values.push(temp);
                }

                let option = {   
                    animation: false,
                    backgroundColor: '#E9EEF7',
                    title: [
                        {
                            text: '原始数据来源：证券交易所',
                            right: '1%',
                            bottom: 8,
                            textStyle:{
                                fontSize:12,
                                fontWeight:'400',
                                color:'#666'
                            }
                        }
                    ],            
                    legend: [
                        {
                            bottom: 8,
                            left: '7%',
                            itemWidth: 20,
                            itemHeight: 12,
                            data: ['波动率(动态)','波动率(滚动)'],
                        }, 
                        {
                            bottom: 8,
                            left: 286,
                            itemWidth: 20,
                            itemHeight: 8,
                            data:[{
                                name: '成交量',
                                icon: 'rect'
                            }]
                        }
                    ],
                    grid: [
                        {
                            left: '8%',
                            right: '2%',
                            top: '3%',
                            height: '40%',
                            show: true,
                            backgroundColor: '#fff',
                            borderColor: 'transparent'
                        },
                        {
                            left: '8%',
                            right: '2%',
                            top: '48%',
                            height: '20%',
                            show: true,
                            backgroundColor: '#fff',
                            borderColor: 'transparent'
                        },
                        {
                            left: '8%',
                            right: '2%',
                            top: '73%',
                            height: '9%',
                        }
                    ],
                    xAxis: [
                        {
                            type: 'category',
                            data: td,
                            axisLine: {
                                onZero: false,
                                lineStyle: {
                                    color: '#666'
                                }
                            },
                            splitLine: {show: false},
                            axisTick: {show: false},
                            axisLabel: {show: false}
                        },
                        {
                            type: 'category',
                            gridIndex: 1,
                            data: td,
                            axisLine: {
                                onZero: false,
                                lineStyle: {
                                    color: '#666'
                                }
                            },
                            splitLine: {show: false},
                            axisLabel: {
                                fontSize: 11,
                                color: '#333',
                                formatter: (value) => {
                                    return value.substr(0, 7)
                                }
                            }
                        },
                        {
                            type: 'category',
                            gridIndex: 2,
                            data:td,
                            axisLine: {
                                onZero: false,
                                lineStyle: {
                                    color: '#666'
                                }
                            },
                            axisTick: {show: false},
                            splitLine: {show: false},
                            axisLabel: {show: false}
                        }
                    ],
                    yAxis: [
                        {
                            name: '指数价格',
                            type: 'value',
                            nameLocation: 'center',
                            nameGap:48,
                            nameTextStyle:{
                                color: '#999'
                            },
                            axisLabel: {
                                color: '#333',
                                fontSize: 11,
                                margin: 5
                            },
                            axisLine: {show: false},
                            axisTick: {show: false},
                            splitLine: {
                                lineStyle: {
                                    color: '#ddd'
                                }
                            },
                            max: function (value) {
                                let max = value.max + (value.max - value.min) / 10
                                return Math.ceil(max / 100) * 100
                            },
                            min: function (value) {
                                let min = value.min - (value.max - value.min) / 10
                                return Math.floor(min / 100) * 100
                            }    
                        },
                        {
                            name: '日波动率（%）',
                            type: 'value',
                            gridIndex: 1,
                            nameLocation: 'center',
                            nameGap:48,
                            nameTextStyle:{
                                color: '#999'
                            },
                            axisLabel: {
                                color: '#333',
                                fontSize: 11,
                                margin: 5
                            },
                            axisLine: {show: false},
                            axisTick: {show: false},
                            splitLine: {
                                lineStyle: {
                                    color: '#ddd'
                                }
                            }
                        },
                        {
                            type: 'value',
                            gridIndex: 2,
                            axisLabel: {show: false},
                            axisLine: {show: false},
                            axisTick: {show: false},
                            splitLine: {show: false},
                            max: 'dataMax'
                        }
                    ],
                    series: [
                        {
                            name: '指数价格',
                            type: 'candlestick',
                            barWidth: '78%',
                            data: values,
                            itemStyle: {
                                color: up_color,
                                color0: down_color,
                                borderColor: null,
                                borderColor0: null
                            }                    
                        },
                        {
                            name: '波动率(动态)',
                            type: 'line',
                            symbol: 'none',
                            lineStyle: {width: 1.5},
                            data: data.realized_vol_chart.dynamic.slice(1,length).map(item => item*100),
                            color:'#5F80A9',
                            xAxisIndex: 1,
                            yAxisIndex: 1,
                        },
                        {
                            name: '波动率(滚动)',
                            type: 'line',
                            symbol: 'none',
                            lineStyle: {width: 1.5},
                            data: data.realized_vol_chart.rolling.slice(1,length).map(item => item*100),
                            color:'#E6BB73',
                            xAxisIndex: 1,
                            yAxisIndex: 1,
                        },
                        {
                            name: '成交量',
                            type: 'bar',
                            xAxisIndex: 2,
                            yAxisIndex: 2,
                            barWidth: '78%',
                            data: data.realized_vol_chart.volume.slice(1,length),
                            color:'#72A8E7'
                        }
                    ],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {},
                        backgroundColor: '#fff',
                        borderWidth: 1,
                        borderColor: '#aaa',
                        padding: 0,
                        textStyle: {
                            fontSize: 12,
                            color: '#666'
                        },
                        position: function (pos, params, el, elRect, size) {
                            var obj = {top: 10};
                            if (+(pos[0] < size.viewSize[0] / 2) === 0) {
                                obj.left = '8%'
                            } else {
                                obj.right = '2%'
                            }
                            return obj;
                        },
                        formatter: function (params) {
                            if(typeof(params)==="undefined"){
                                return ;
                            }
                            let kData = params[params.findIndex(item => item.seriesName == '指数价格')],
                                dtData = params[params.findIndex(item => item.seriesName == '波动率(动态)')],
                                gdData = params[params.findIndex(item => item.seriesName == '波动率(滚动)')],
                                barData = params[params.findIndex(item => item.seriesName == '成交量')]
                            let date = kData['axisValue'],
                                index = td.indexOf(date),
                                formatD = date.replace(/-/g, '/')
                            formatD += '日一二三四五六'.charAt(new Date(formatD).getDay())
                            let showHtm = ''
                            let contentList = [{
                                name: '时间',
                                content:`${formatD}`
                            },{
                                name: '开盘',
                                content: `${keepTwodec(kData.data[1])}`,
                                withColor: true,
                                exp: kData.data[1]>data.realized_vol_chart.open[index]
                            },{
                                name: '收盘',
                                content: `${keepTwodec(kData.data[2])}`,
                                withColor: true,
                                exp: kData.data[2]>data.realized_vol_chart.close[index]
                            },{
                                name: '最高',
                                content: `${keepTwodec(kData.data[3])}`,
                                withColor: true,
                                exp: kData.data[3]>data.realized_vol_chart.high[index]
                            },{
                                name: '最低',
                                content: `${keepTwodec(kData.data[4])}`,
                                withColor: true,
                                exp: kData.data[4]>data.realized_vol_chart.low[index]
                            },{
                                name: '涨幅',
                                content: `${keepTwodec(kData.data[5]*100)}%`,
                                withColor: true,
                                exp: kData.data[5]>0
                            },{
                                name: !barData ? '' : '成交量',
                                content: !barData ? '' : 
                                        `${keepTwodec(barData.data > 100000000 ? barData.data/100000000 : barData.data/10000)}
                                        ${barData.data > 100000000 ? '亿' : '万'}`
                            },{
                                name: '波动率（动态）',
                                content: `${dtData ? keepTwodec(dtData.data) : '--'}%`
                            },{
                                name: '波动率（滚动）',
                                content: `${gdData ? keepTwodec(gdData.data) : '--'}%`
                            }]
                            contentList.forEach((item,index) => {
                                let htm = ''
                                item.name && (htm = `
                                    ${index == 7 ? `<a style="display: block; width: 100%; height: 1px; background: #aaa; margin-top: 5px;"></a>` : ``}
                                    <p style="height: 20px; padding: 0 5px; margin-top: ${index == 0 ? '6px' : '0'}; margin-bottom: ${index == contentList.length-1 ? '6px' : '0'};">
                                        <span style="float: left; margin-right: 10px; margin-top: ${index == 7 ? 6 : 0}px;">${item.name}</span>
                                        <span style="float: right; margin-top: ${index == 7 ? 6 : 0}px; color: ${item.withColor ? (item.exp ? up_color : down_color) : ''};">${item.content}</span>
                                    </p>
                                `)
                                showHtm += htm
                            });
                            return showHtm
                        },
                    }
                };

                if(!['000001', '399001', '399006', '000300'].includes(params.index_code)){
                    option.xAxis = option.xAxis.slice(0,2);
                    option.yAxis = option.yAxis.slice(0,2);
                    option.series = option.series.slice(0,3);
                }

                let gridNum = new Array(option.xAxis.length).fill('').map((item, index) => index)
                option.axisPointer = {
                    link: [
                        {xAxisIndex: gridNum}
                    ]
                }
                option.dataZoom = [
                    {
                        type: 'slider',
                        xAxisIndex: gridNum,
                        top: '84%',
                        bottom: '9%',
                        showDetail: false,
                        minSpan: 25 / td.length * 100
                    }
                ]

                commit('SET_OPTION', option) ; 
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
}

function keepTwodec(data) {
    if (!data) return '--'
    if (typeof(data) == 'string') return data
    return data.toFixed(2)
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}
