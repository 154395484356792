<template>
    <div class="container footer-bar">
        <div class="content">
            <div class="info">
                <p class="title">关于我们</p>
                <h6 class="a" @click="goTo('introduce')">公司介绍</h6>
                <h6 class="a" @click="goTo('contact')">联系我们</h6>
            </div>

            <div class="info">
                <p class="title">咨询反馈</p>
                <h6>邮箱：customer@zhijuninvest.com</h6>
                <h6>座机：0571-8617 0015</h6>
                <h6>客服：153 9428 3793</h6>
            </div>

            <div class="info">
                <p class="title">关注我们</p>
                <div class="inline">
                    <img src="../assets/qrcode.png" alt="">
                    <h6>智君科技公众号</h6>
                </div>
                <div class="inline">
                    <img src="../assets/xiaozhi.png" alt="">
                    <h6>微信客服</h6>
                </div>
            </div>
        </div>
        <div class="reg-num">
            <span style="font-size:14px;">&copy;</span> 2018 zhijun.
            All Rights Reserved.
            <a href="https://beian.miit.gov.cn/" target="_blank">
            浙ICP备18031098号-1
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterBar',
    data() {
        return {
            
        }
    },
    methods: {
        goTo(id) {
            this.$router.push({
                path: '/about',
                query: {
                    id: id
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.footer-bar{
    .content{
        padding: 32px 0;
        display: flex;
        justify-content: space-between;
        color: #333333;
        .info{
            text-align: left;
            p, h1, h6{
                margin: 0;
            }
            .title{
                font-size: 16px;
                line-height: 30px;
            }
            h1{
                font-size: 30px;
            }
            h6{
                line-height: 24px;
                color: #999999;
            }
            .a{
                cursor: pointer;
                &:hover{
                    color: #333333;
                }
            }
            .inline{
                display: inline-block;
                text-align: center;
                &:last-child{
                    margin-left: 24px;
                }
                
                img{
                    width: 115px;
                    vertical-align: bottom;
                }
            }
        }
    }
    .reg-num{
        height: 80px;
        line-height: 80px;
        background: #535353;
        font-size: 12px;
        color: #999999;
    }
} 
</style>